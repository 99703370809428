import styled from '@emotion/styled'

import mq from './breakpoints'

export const BgImage = styled.div`
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100vw;

  div {
    height: 100vh;
  }
`

export const ContentContainer = styled.div`
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 3rem;
  text-align: center;
  color: white;

  ${mq['md']} {
    font-size: 2.5rem;
  }

  ${mq['sm']} {
    font-size: 2.2rem;
  }
`
