import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useQueryParam, StringParam } from 'use-query-params'

import Layout from 'components/Layout'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import { useAuth0 } from 'utils/react-auth0-spa'
import { getAuth0LockLangCode } from 'utils/auth0LockLangCode'
import { userHasActiveSubscription } from 'utils/userHasActiveSubscription'
import Loading from 'components/General/Loading'
import {
  BgImage,
  ContentContainer,
} from 'components/styleComponents/EventComponents'

const EVENT_PAGE = '/event/'

const Event = () => {
  const {
    productAccess,
    fetchUserProductAccess,
    userStateLoading,
  } = useContext(UserContext)
  const {
    meetingUrl,
    getSessionAccess,
    subscriptionStateLoading,
    isSessionAccessError,
  } = useContext(SubscriptionContext)
  const { isAuthenticated, auth0Loading, loginWithRedirect } = useAuth0()
  const { t, i18n, ready } = useTranslation('eventPage')
  const [eventId] = useQueryParam('id', StringParam)
  const [
    doesNotHaveValidEventAccess,
    setDoesNotHaveValidEventAccess,
  ] = useState(false)

  useEffect(() => {
    if (
      !auth0Loading &&
      !userStateLoading &&
      !subscriptionStateLoading &&
      isAuthenticated &&
      !productAccess
    ) {
      fetchUserProductAccess()
    } else if (
      !auth0Loading &&
      !userStateLoading &&
      !subscriptionStateLoading &&
      !isAuthenticated
    ) {
      loginWithRedirect({
        ui_locales: getAuth0LockLangCode(i18n.language),
        redirect_uri: process.env.GATSBY_WEBSITE_URL + EVENT_PAGE,
      })
    }
  }, [
    auth0Loading,
    userStateLoading,
    subscriptionStateLoading,
    fetchUserProductAccess,
    isAuthenticated,
    productAccess,
    loginWithRedirect,
    i18n.language,
  ])

  useEffect(() => {
    if (
      eventId &&
      eventId !== '' &&
      productAccess &&
      (productAccess.isFluentWorldsAcademyTeacher ||
        userHasActiveSubscription(productAccess))
    ) {
      getSessionAccess(eventId)
      setDoesNotHaveValidEventAccess(false)
    } else if (
      productAccess &&
      !productAccess.isFluentWorldsAcademyTeacher &&
      !userHasActiveSubscription(productAccess)
    ) {
      toast.error(t('noPurchasesMessage'))
      setDoesNotHaveValidEventAccess(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productAccess, eventId])

  useEffect(() => {
    if (meetingUrl) {
      navigate(meetingUrl)
    }
  }, [meetingUrl])

  useEffect(() => {
    if (!eventId || eventId === '') {
      setDoesNotHaveValidEventAccess(true)
      toast.error(t('invalidEventId'))
    }
  }, [eventId, t])

  const showLoading =
    (subscriptionStateLoading ||
      userStateLoading ||
      auth0Loading ||
      !meetingUrl) &&
    !isSessionAccessError &&
    !doesNotHaveValidEventAccess

  return (
    <Layout>
      <BgImage>
        <StaticImage
          src="../images/jpg/StepsPlaceholder2.jpg"
          layout="fullWidth"
          loading="eager"
          alt="Background image"
        />
      </BgImage>

      {!ready ? null : (
        <ContentContainer>
          {!isSessionAccessError &&
            !doesNotHaveValidEventAccess &&
            t('generatingLink')}
          {isSessionAccessError && t('errorMessageNoCourseAccess')}
          {doesNotHaveValidEventAccess && t('errorMessageNoCourseAccess')}
          {showLoading ? <Loading /> : null}
        </ContentContainer>
      )}
    </Layout>
  )
}

export default Event
