const LoadingAnimation = {
  v: '5.5.10',
  fr: 30,
  ip: 0,
  op: 210,
  w: 1155,
  h: 700,
  nm: 'loading black',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 209, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [573, 353, 0], ix: 2 },
        a: { a: 0, k: [2.18, 2.965, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [245.328, 245.328], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 30,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 60,
                    s: [24],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 150,
                    s: [24],
                  },
                  { t: 180, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 30,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 60,
                    s: [29],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 150,
                    s: [29],
                  },
                  { t: 180, s: [100] },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 30,
                    s: [103],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 60,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 150,
                    s: [0],
                  },
                  { t: 180, s: [68] },
                ],
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.984314024682, 0.984314024682, 0.96470600203, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 27, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.18, 2.965], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 219,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 209, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [573, 353, 0], ix: 2 },
        a: { a: 0, k: [2.18, 2.965, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [245.328, 245.328], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 25,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 55,
                    s: [36],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 155,
                    s: [36],
                  },
                  { t: 185, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 25,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 55,
                    s: [54],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 155,
                    s: [54],
                  },
                  { t: 185, s: [100] },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 25,
                    s: [97],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 55,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 155,
                    s: [0],
                  },
                  { t: 185, s: [65] },
                ],
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.792156862745098, 0.792156862745098, 0.792156862745098, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 27, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.18, 2.965], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 219,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Shape Layer 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 42, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 209, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [573, 353, 0], ix: 2 },
        a: { a: 0, k: [2.18, 2.965, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [245.328, 245.328], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [0],
                  },
                  {
                    i: { x: [0.709], y: [1] },
                    o: { x: [0.302], y: [0] },
                    t: 50,
                    s: [0],
                  },
                  {
                    i: { x: [0.779], y: [1] },
                    o: { x: [0.426], y: [0] },
                    t: 160,
                    s: [0],
                  },
                  { t: 190, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [0],
                  },
                  {
                    i: { x: [0.709], y: [1] },
                    o: { x: [0.302], y: [0] },
                    t: 50,
                    s: [32],
                  },
                  {
                    i: { x: [0.779], y: [1] },
                    o: { x: [0.426], y: [0] },
                    t: 160,
                    s: [32],
                  },
                  { t: 190, s: [100] },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [-277],
                  },
                  {
                    i: { x: [0.709], y: [1] },
                    o: { x: [0.302], y: [0] },
                    t: 50,
                    s: [-55],
                  },
                  {
                    i: { x: [0.779], y: [1] },
                    o: { x: [0.426], y: [0] },
                    t: 160,
                    s: [-55],
                  },
                  { t: 190, s: [64] },
                ],
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.984314024682, 0.984314024682, 0.96470600203, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 27, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.18, 2.965], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 219,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 80, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 209, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [573, 353, 0], ix: 2 },
        a: { a: 0, k: [2.18, 2.965, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [245.328, 245.328], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 15,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 45,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 165,
                    s: [0],
                  },
                  { t: 195, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 15,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 45,
                    s: [8],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 165,
                    s: [8],
                  },
                  { t: 195, s: [100] },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 15,
                    s: [-288],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 45,
                    s: [-119],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 165,
                    s: [-119],
                  },
                  { t: 195, s: [64] },
                ],
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.984314024682, 0.984314024682, 0.96470600203, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 27, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.18, 2.965], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 219,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 20, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 209, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [573, 353, 0], ix: 2 },
        a: { a: 0, k: [2.18, 3.965, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [245.328, 245.328], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 40,
                    s: [20],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 170,
                    s: [20],
                  },
                  { t: 200, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 40,
                    s: [100],
                  },
                  {
                    i: { x: [0.833], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 170,
                    s: [100],
                  },
                  { t: 200, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 60, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.98431372549, 0.98431372549, 0.964705882353, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 27, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.18, 2.965], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 219,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Shape Layer 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [573, 353, 0], ix: 2 },
        a: { a: 0, k: [3.797, -1.703, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 10,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 200,
              s: [100, 100, 100],
            },
            { t: 209, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [332.594, 332.594], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.2901960784313726,
                  0.5647058823529412,
                  0.8862745098039215,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [3.797, -2.703], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 219,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default LoadingAnimation
