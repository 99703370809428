export const userHasActiveSubscription = (productAccess) => {
  if (
    productAccess &&
    (productAccess.fluentworldsAcademyGeneralLite ||
      productAccess.fluentworldsAcademyGeneralClassic ||
      productAccess.fluentworldsAcademyGeneralPremium ||
      productAccess.fluentworldsAcademyGeneralComplete ||
      productAccess.fluentworldsAcademyAcademicLite ||
      productAccess.fluentworldsAcademyAcademicClassic ||
      productAccess.fluentworldsAcademyAcademicPremium ||
      productAccess.fluentworldsAcademyBusinessLite ||
      productAccess.fluentworldsAcademyBusinessClassic ||
      productAccess.fluentworldsAcademyBusinessPremium ||
      productAccess.fluentworldsAcademyIndividualLite ||
      productAccess.fluentworldsAcademyIndividualClassic ||
      productAccess.fluentworldsAcademyIndividualPremium)
  ) {
    return true
  }
  return false
}
